module controllers {
    'use strict';
    export module sales {
        interface ISalesUpdateScope extends ng.IScope { }

        interface ISalesUpdateParams extends ng.ui.IStateParamsService {
            soId: number;
        }

        export class SalesOrderUpdateCtrl {
            static $inject = [
                "$scope",                      
                'salesOrderService',         
                '$rootScope',                   
                'generalService',               
                '$q',                   
                '$stateParams',
                'currencyService',
                'classificationValueService',
                'supplierService',
                'termsOfPaymentService',
                'costModelService',
                "$anchorScroll",
                "$state",
                "purchaseOrderSKUService",
                "countryService",
                "incotermService",
                "termsOfDeliveryService",
                "consigneeService",
                "documentRepositoryService",
                "bsLoadingOverlayService",
                "lookupService",
                "skuService",
                "$timeout",
                '$transitions',
                'customerService',
                'bankService',
                'shippingPriorityService',
                'routeCodeService',
                'transportModeService',
                'unLocationCodeService',
                'warehouseService',
                'cartageContractorService',
                'sundryItemService',
                'salesOrderSKUService',
                'salesOrderSundryItemService',
                'entityService',
                'permitService',
                'menuService',
                "customAttributeService", 
                "customAttributeAnswerOptionService"
            ];

            public SalesOrderSearch: { id: number };
            public SalesOrder: interfaces.sales.ISalesOrder;

            public SOStatus: interfaces.master.IStatusData;

            showOnlyProhibited: boolean = false;
            showAll: boolean = false;

            soNumber: string;

            apiSOSkuList: uiGrid.IGridApi;
            apiSOSundryList: uiGrid.IGridApi;

            selectedTab: number = 0;

            documentRepository: interfaces.documentManagement.IDocumentRepository;

            termsOfDeliveryList: Array<interfaces.applicationcore.IDropdownModel>;

            UnloadingCountryList: Array<interfaces.applicationcore.IDropdownModel>;
            placeOfUnloadingList: Array<interfaces.applicationcore.IDropdownModel>;

            LoadingCountryList: Array<interfaces.applicationcore.IDropdownModel>;
            placeOfLoadingList: Array<interfaces.applicationcore.IDropdownModel>;

            deliverToWarehouseList: Array<interfaces.applicationcore.IDropdownModel>;

            incotermEditMode: boolean = false;
            incotermOverride: boolean = false;

            viewConsignmentRight: boolean = false;
            editConsingmentRight: boolean = false;

              //Attribute Grid
              apiCA: uiGrid.IGridApi;
              attributeList: interfaces.applicationcore.IDropdownModel[] = [];
              attributeAnswerOptionList: interfaces.applicationcore.IDropdownModel[] = [];

            constructor(
                private $scope: ISalesUpdateScope,
                private salesOrderService: interfaces.sales.ISalesOrderService,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $stateParams: ISalesUpdateParams,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private supplierService: interfaces.master.ISupplierService,
                private termsOfPaymentService: interfaces.master.ITermsOfPaymentService,
                private costModelService: interfaces.costing.ICostModelService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService,
                private purchaseOrderSKUService: interfaces.purchase.IPurchaseOrderSKUService,
                public countryService: interfaces.applicationcore.ICountryService,
                public incotermService: interfaces.applicationcore.IIncotermService,
                public termsOfDeliveryService: interfaces.master.ITermsOfDeliveryService,
                public consigneeService: interfaces.master.IConsigneeService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public bsLoadingOverlayService,
                private lookupService: interfaces.applicationcore.ILookupService,
                public skuService: interfaces.master.ISKUService,
                public $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public customerService: interfaces.master.ICustomerService,
                public bankService: interfaces.master.IBankService,
                public shippingPriorityService: interfaces.master.IShippingPriorityService,
                public routeCodeService: interfaces.master.IRouteCodeService,
                public transportModeService: interfaces.applicationcore.ITransportModeService,
                public unLocationCodeService: interfaces.applicationcore.IUNLocationCodeService,
                public warehouseService: interfaces.master.IWarehouseService,
                public cartageContractorService: interfaces.master.ICartageContractorService,
                public sundryItemService: interfaces.master.ISundryItemService,
                public salesOrderSKUService: interfaces.sales.ISalesOrderSKUService,
                public salesOrderSundryItemService: services.sales.salesOrderSundryItemService,
                private entityService: interfaces.applicationcore.IEntityService,
                private permitService: interfaces.master.IPermitService,
                private menuService: interfaces.applicationcore.IMenuService,
                public customAttributeService: services.master.customAttributeService,
                public customAttributeAnswerOptionService: services.master.customAttributeAnswerOptionService,
                ) {

                if ($stateParams.soId === null) {
                    return;
                }

                this.SalesOrderSearch = { id: $stateParams.soId };
                
                
                this.loadSO();

                //Reloads PO after an update to SKU or DI.
                $transitions.onSuccess({
                    to: 'auth.SalesOrders.Update',
                    from: 'auth.SalesOrders.Update.**'
                }, () => {
                    this.loadSO();
                });
            }

            loadSO() {
                if (this.apiSOSkuList) {
                    this.apiSOSkuList.rowEdit.flushDirtyRows();
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                },
                    () => {
                        return this.salesOrderService.getSalesOrder().query(this.SalesOrderSearch, (result) => {
                            this.SalesOrder = result;
                            this.gvwSOSku.data = this.SalesOrder.SalesOrderDetailSKUList;                          
                            this.gvwSOSundry.data = this.SalesOrder.SalesOrderSundryItemsList;
                            this.soNumber = this.SalesOrder.OrderNumber;
                            this.gvwSalesOrderAttribute.data = result.SalesOrderAttributeList;
                            
                            this.gvwPRList.data = this.SalesOrder.SalesOrderPRList;
                            this.gvwPRList.totalItems = this.SalesOrder.SalesOrderPRList.length;
                            this.incotermEditMode = false;
                            this.getAllowIncotermOverrideRight();
                            this.getViewExportConsignmentRight();
                            this.getEditExportConsignmentRight();
                            this.loadControls();
                            this.loadStatus();

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            getAllowIncotermOverrideRight() {
                this.incotermOverride = false;
                return this.menuService.getGTSConnectMenuItem(693).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.incotermOverride = true;
                    }
                }).$promise;
            }    

            getViewExportConsignmentRight() {
                this.viewConsignmentRight = false;
                return this.menuService.getGTSConnectMenuItem(380).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.viewConsignmentRight = true;
                    }
                }).$promise;
            }    

            getEditExportConsignmentRight() {
                this.editConsingmentRight = false;
                return this.menuService.getGTSConnectMenuItem(606).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.editConsingmentRight = true;
                    }
                }).$promise;
            }       
            
            ViewConsignment = (conId: number) => {
                var url = '#!/ConsignmentExports/UpdateExport/'+conId;
                window.open(url, '_blank');
              }

            incotermOverrideClick(){
                this.incotermEditMode = true;
            }

            loadControls() {
                var loadPromises: ng.IPromise<any>[] = [];
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                },
                    () => {
                        loadPromises.push(this.getDocuments(this.showAll));

                        return this.$q.all(loadPromises);
                    });
            }

            loadTermsOfPayments(searchText: string) {
                var defered = this.$q.defer();

                if (this.SalesOrder) {
                    this.termsOfPaymentService.getTermsOfPaymentDropdown(this.SalesOrder.OwnerEntityId, searchText).query({}, (termsOfPaymentList: Array<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(termsOfPaymentList);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defered.resolve([]);
                    });
                } else {
                    defered.resolve([]);
                }

                return defered.promise;
            }

            loadCurrencies() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(currencyList);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });


                return defered.promise;
            }

            loadIncoTerms() {
                var defered = this.$q.defer();

                this.incotermService.getDropdownList().query((data: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadTermsOfDeliveries() {
                var defered = this.$q.defer();

                if (!this.SalesOrder || !this.SalesOrder.Incoterm) {
                    defered.resolve([]);
                    return defered.promise;
                }
                this.termsOfDeliveryService.getDropdownList().query({ ownerEntityId: this.SalesOrder.OwnerEntityId, incoTermId: this.SalesOrder.Incoterm.Id }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    this.termsOfDeliveryList = list;
                    defered.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadConsignees(searchText: string) {
                var defered = this.$q.defer();

                if (!this.SalesOrder) {
                    defered.resolve([]);
                    return defered.promise;
                }

                this.consigneeService.getDropdownList(this.SalesOrder.OwnerEntityId, searchText).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadSuppliers(searchText: string) {
                var defered = this.$q.defer();

                if (!this.SalesOrder) {
                    defered.resolve([]);
                    return defered.promise;
                }

                if (this.$rootScope.Entity.Id) {
                    this.supplierService.getSupplierDropdown(this.$rootScope.Entity.Id, searchText).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(list);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defered.resolve([]);
                    });
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        this.supplierService.getSupplierDropdown(resultEntity.Id, searchText).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            defered.resolve(list);
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            defered.resolve([]);
                        });
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }

                return defered.promise;
            }

            loadCustomers() {
                var defered = this.$q.defer();

                if (!this.SalesOrder) {
                    defered.resolve([]);
                    return defered.promise;
                }

                if (this.$rootScope.Entity.Id) {
                    this.customerService.getDropdownList(this.$rootScope.Entity.Id, "").query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(list);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defered.resolve([]);
                    });
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        this.customerService.getDropdownList(resultEntity.Id, "").query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            defered.resolve(list);
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            defered.resolve([]);
                        });
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }

                return defered.promise;
            }

            loadBanks() {
                var defered = this.$q.defer();

                if (!this.SalesOrder) {
                    defered.resolve([]);
                    return defered.promise;
                }

                if (this.$rootScope.Entity.Id) {
                    this.bankService.getDropdownList(this.$rootScope.Entity.Id, "").query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(list);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defered.resolve([]);
                    });
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        this.bankService.getDropdownList(resultEntity.Id, "").query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            defered.resolve(list);
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            defered.resolve([]);
                        });
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }

                return defered.promise;
            }

            public loadShippingPriorities() {
                var defer = this.$q.defer();

                if (this.SalesOrder) {
                    this.shippingPriorityService.getDropdownList().query(
                        { 
                            entityId: this.SalesOrder.OwnerEntityId, 
                            transportModeId: this.SalesOrder.TransportMethod?this.SalesOrder.TransportMethod.Id:null,
                            IsInbound: false
                        }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                } else {
                    defer.resolve([]);
                }

                return defer.promise;
            }


            public loadRouteCodes() {
                var defer = this.$q.defer();

                if (this.SalesOrder && this.SalesOrder.LoadingCountry.Id) {
                    this.routeCodeService.getDropdownListByCountry(this.SalesOrder.OwnerEntityId, false, this.SalesOrder.LoadingCountry.Id).query((data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                } else {
                    defer.resolve([]);
                }


                return defer.promise;
            }

            public loadTransportType() {
                var defer = this.$q.defer();

                this.transportModeService.getDropdownList().query({}, (data) => {
                    defer.resolve(data);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defer.resolve([]);
                });

                return defer.promise;
            }

            public loadCountries() {
                var defer = this.$q.defer();

                this.countryService.getDropdownList().query({}, (data) => {
                    defer.resolve(data);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defer.resolve([]);
                });

                return defer.promise;
            }

            public loadPlaceOfLoading(searchText) {
                var defer = this.$q.defer();

                if (this.SalesOrder && this.SalesOrder.LoadingCountry.Id) {
                    this.unLocationCodeService.getDropdownList().query({ countryId: this.SalesOrder.LoadingCountry.Id, searchText: searchText }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                } else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            public loadPlaceOfUnloading(searchText: string) {
                var defer = this.$q.defer();

                if (this.SalesOrder && this.SalesOrder.UnLoadingCountry.Id) {
                    this.unLocationCodeService.getDropdownList().query({ countryId: this.SalesOrder.UnLoadingCountry.Id, searchText: searchText }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                }
                else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            public loadWarehouse() {
                var defer = this.$q.defer();

                if (this.SalesOrder && this.SalesOrder.PlaceOfLoading) {
                    this.warehouseService.getDropdownList().query({ ownerEntityId: this.SalesOrder.OwnerEntityId, unLocationCodeId: this.SalesOrder.PlaceOfLoading.Id }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                }
                else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            public loadCartageContractors() {
                var defer = this.$q.defer();

                if (this.SalesOrder && this.SalesOrder.PlaceOfUnloading) {
                    this.cartageContractorService.getDropdownList().query({ ownerEntityId: this.SalesOrder.OwnerEntityId }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                }
                else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            public loadRouteDefaults(route: interfaces.applicationcore.IDropdownModel) {

                this.routeCodeService.getRouteData().query({ routeId: route ? route.Id : 0 },
                    (routeCodeData: interfaces.master.IRouteCode) => {
                        this.setRouteDefaults(routeCodeData);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    });
            }

            public setRouteDefaults(routeDefaultData: interfaces.master.IRouteCode) {
                if (routeDefaultData && routeDefaultData.Code) {
                    this.SalesOrder.PlaceOfLoading = routeDefaultData.LoadingLocation;
                    if (!this.SalesOrder.UnLoadingCountry || this.SalesOrder.UnLoadingCountry.Id != routeDefaultData.DestinationCountry.Id) {
                        this.SalesOrder.UnLoadingCountry = routeDefaultData.DestinationCountry;
                        this.placeOfUnloadingList = [];
                    }
                    this.SalesOrder.PlaceOfUnloading = routeDefaultData.UnloadingLocation;
                    this.SalesOrder.TransportMethod = routeDefaultData.TransportMode;
                    if (routeDefaultData.DispatchWarehouse !== null)
                        this.SalesOrder.DispatchWarehouse = routeDefaultData.DispatchWarehouse;
                    if (routeDefaultData.MasterCartageContractor !== null)
                        this.SalesOrder.CartageContractor = routeDefaultData.MasterCartageContractor;
                }
            }

            public destinationCountryChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.SalesOrder.UnLoadingCountry = model;
                this.SalesOrder.PlaceOfUnloading = undefined;
                this.placeOfUnloadingList = [];
            }

            public loadingCountryChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.SalesOrder.LoadingCountry = model;
                this.SalesOrder.PlaceOfLoading = undefined;
                this.placeOfLoadingList = [];
            }

            getDocuments(showAll:boolean) {
                return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.SalesOrder.OrderNumber, systemType: Enum.EnumSystemType.SalesOrder, ownerEntityId: this.SalesOrder.OwnerEntityId , showAll:showAll}, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    this.documentRepository = documentRepository;

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }


            loadStatus() {
                this.SOStatus = {
                    StatusName: this.SalesOrder.StatusCode + ' - ' + this.SalesOrder.StatusName,
                    Id: this.SalesOrder.Id,
                    EntityId: this.SalesOrder.OwnerEntityId,
                    CurrentStatusId: this.SalesOrder.StatusId,
                    CurrentStatusComments: this.SalesOrder.StatusLastComments,
                    CurrentStatusStampDate: this.SalesOrder.StatusLastUpdateStampDate,
                    CurrentStatusStampFullName: this.SalesOrder.StatusLastUpdateStampFullName,
                    CurrentStatusType: Enum.EnumStatusType.SalesOrder,
                    EnumStatusType: Enum.EnumStatusType.SalesOrder,
                    EnumStatusAuthorisationType: Enum.EnumStatusAuthorisationType.SalesOrder,
                    IsEditable: true
                };
            }

            downloadToExcel() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                },
                    () => {
                        this.salesOrderService.GetSalesOrderDetailSKUListExcel(this.SalesOrder.Id).then(() => {
                        }, (data) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                        });
                    });
            }

            public registerSOSundryApi(gridApi: uiGrid.IGridApi) {
                this.apiSOSundryList = gridApi;


               this.apiSOSundryList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                   this.apiSOSundryList.selection.selectRow(gridSelection.row.entity);
                  
                });
            }

            public gvwSOSundry: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerSOSundryApi(gridApi) },
                cellEditableCondition: () => { return this.SalesOrder ? this.SalesOrder.LockdownType < 2 : true },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    field: "Id",
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-if="MODEL_COL_FIELD || MODEL_COL_FIELD <= 0" class="btn btn-default btn-sm" ui-sref="auth.SalesOrders.Update.SundryItem({ sosId: MODEL_COL_FIELD })">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-disabled="grid.appScope.salesOrderUpdateCtrl.SalesOrder.LockdownType > 1" ng-click="grid.appScope.salesOrderUpdateCtrl.addInlineSundryItem()">
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>`
                }, {
                    name: "SEQ",
                    displayName: "#",
                    field: 'SequenceNumber',
                    enableFiltering: false,
                    cellClass: 'text-right',
                    cellFilter: 'number: 0',
                    width: 35
                }, {
                    name: "CODE",
                    displayName: "* Sundry Item Code",
                    field: 'SundryItem',
                    enableCellEdit: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Code}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.salesOrderUpdateCtrl.loadSundryItem(searchText)" required="true" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            if (cellValue) {
                                return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                            } else {
                                return false;
                            }
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiSOSundryList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "DESC",
                    displayName: "Sundry Item Description",
                    field: 'SundryItem',
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    width: 275,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Description}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="desc-{{MODEL_COL_FIELD.$$hashKey}}" autocomplete="off" ng-model="MODEL_COL_FIELD.Description" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor ng-change="grid.appScope.salesOrderUpdateCtrl.apiSOSundryList.rowEdit.setRowsDirty([row.entity]);" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "QTY",
                    displayName: "* Quantity",
                    field: 'Quantity',
                    width: 100,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="qty-{{MODEL_COL_FIELD.$$hashKey}}" autocomplete="off" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "SELLPRICE",
                    displayName: "* Price",
                    field: 'SellingPrice',
                    width: 100,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="qty-{{MODEL_COL_FIELD.$$hashKey}}" autocomplete="off" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "SELLVALUE",
                    displayName: "Value",
                    field: 'SellingValue',
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                    }, {
                        name: "CURCODE",
                        displayName: "Currency",
                        field: 'Currency',
                        cellClass: 'text-left',
                        width: 100,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Code}}</div>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }
                    , {
                    name: "VATVALUE",
                    displayName: "VAT Value",
                    field: 'VATValue',
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                    }, {
                        name: "BASESELLVALUE",
                        displayName: "Base Currency Value",
                        field: 'BaseCurrencyValue',
                        cellClass: 'text-right',
                        cellFilter: 'number: 2',
                        width: 110,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }
                ]
            };

            addInlineSundryItem() {
                this.SalesOrder.SalesOrderSundryItemsList.push(<interfaces.sales.ISalesOrderSundryItemDisplay>{
                    Quantity: 0,
                    SellingValue: 0,
                    SellingPrice: 0,
                    VATValue: 0
                });
            }

            loadSundryItem(searchText: string) {
                if (this.SalesOrder) {
                    return this.sundryItemService.getDropdownList(this.SalesOrder.OwnerEntityId,false, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            routeChanged(model: interfaces.applicationcore.IDropdownModel)
            {
                if (model) {
                    this.SalesOrder.Route = model;
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                },
                    () => {
                        return this.SaveSalesOrder().then((data) => {
                            this.$anchorScroll("topAnchor");
                            this.loadSO();
                        }, (data) => {
                            this.$anchorScroll("topAnchor");
                        });
                        });
                }
            }

            SaveSalesOrder() {
                var defered = this.$q.defer();

                if (this.apiSOSundryList) {
                    this.SalesOrder.SalesOrderSundryItemsList = _.map(this.apiSOSundryList.rowEdit.getDirtyRows(), 'entity');
                } else {
                    this.SalesOrder.SalesOrderSundryItemsList = [];
                }

                if (this.apiSOSkuList) {
                    this.SalesOrder.SalesOrderDetailSKUList = _.map(this.apiSOSkuList.rowEdit.getDirtyRows(), 'entity');
                }
                else {
                    this.SalesOrder.SalesOrderDetailSKUList = [];
                }

                //P&R
                if (this.apiPRList && this.apiPRList.rowEdit.getDirtyRows().length > 0) {
                    this.SalesOrder.SalesOrderPRList = _.map(this.apiPRList.rowEdit.getDirtyRows(), (o) => {
                        return o.entity;
                    });
                } else {
                    this.SalesOrder.SalesOrderPRList = [];
                }

                this.salesOrderService.saveSalesOrder().query(this.SalesOrder, (result: interfaces.applicationcore.IMessageHandler) =>
                    {
                        if (!result.HasErrorMessage) {
                            if (this.apiSOSundryList)
                                this.apiSOSundryList.rowEdit.setRowsClean(this.SalesOrder.SalesOrderSundryItemsList);

                            if (this.apiSOSkuList)
                                this.apiSOSkuList.rowEdit.setRowsClean(this.SalesOrder.SalesOrderDetailSKUList);

                            if (this.apiPRList)
                                this.apiPRList.rowEdit.setRowsClean(this.SalesOrder.SalesOrderPRList);
                        }

                        this.generalService.displayMessageHandler(result);
                        defered.resolve(result);

                    }, (failureMessage) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                        defered.reject(failureMessage);
                    });

                return defered.promise;
            }

            Save() {
                this.generalService.displayConfirmationBox("Confirm", "Save Sales Order?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'so.update'
                        },
                            () => {                                
                                return this.SaveSalesOrder().then((data) => {
                                    this.$anchorScroll("topAnchor");
                                    this.loadSO();
                                }, (data) => {
                                    this.$anchorScroll("topAnchor");
                                });
                            });
                    }
                });
            }

            SaveClose() {
                this.generalService.displayConfirmationBox("Confirm", "Save Sales Order?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'so.update'
                        },
                            () => {
                                return this.SaveSalesOrder().then((data) => {
                                    this.$state.go("^");
                                   
                                }, (failureData) => {
                                    this.$anchorScroll("topAnchor");
                                });
                            })
                    }
                });
            }

            Close() {
                this.$state.go("^");
            }

            updateStatus = (newStatusId: number, comments: string) => {
                var paramsObject: interfaces.master.IStatusChange = {
                    referenceId: this.SalesOrder.Id,
                    newStatusId: newStatusId,
                    comments: comments
                };
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                },
                    () => {
                       return this.SaveSalesOrder().then(() => {
                            this.salesOrderService.changeStatus().save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(data);
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'so.update'
                                }, () => {
                                        this.selectedTab = 4;
                                        return this.loadSO();
                                    });
                                
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'so.update'
                                }, () => {
                                        this.selectedTab = 4;
                                        return this.loadSO();
                                    });
                            });
                        }, () => {
                            return;
                        });
                    });
            };
            
            gvwSOSku: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerSkuGridApi(gridApi); },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    field: "SalesOrderSKUId",
                    enableFiltering: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button"  ng-click="grid.appScope.salesOrderUpdateCtrl.editSKULineClick(MODEL_COL_FIELD)"  class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-disabled="grid.appScope.salesOrderUpdateCtrl.SalesOrder.LockdownType >= 1" ng-click="grid.appScope.salesOrderUpdateCtrl.addInlineSku()">
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>`
                }, {
                    name: "SEQNUM",
                    displayName: "#",
                    field: "SequenceNumber",
                    enableFiltering: false,
                    type: "number",
                    cellClass: 'text-right',
                    width: 30,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false
                }, {
                    name: "PRODCODE",
                    displayName: "Product",
                    field: "ProductCode",
                    width: 100,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "SKUCODE",
                    displayName: "* SKU",
                    field: "SKU",
                    enableFiltering: true,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => { return this.SalesOrder ? this.SalesOrder.LockdownType < 1 : true },
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Code}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.salesOrderUpdateCtrl.loadSKUs(searchText)" required="true" pagination-search="true" refresh-delay="1000"
                                                ng-change="grid.appScope.salesOrderUpdateCtrl.skuChanged(model, row.entity)"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiSOSkuList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "SKUDESCRIPTION",
                    displayName: "SKU Description",
                    field: "SKU.Description",
                    width: 300,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => { return this.SalesOrder ? this.SalesOrder.LockdownType < 2 : true },
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-skudesc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "ORDQTY",
                    displayName: "* Order Qty",
                    field: "OrderedQty",
                    type: "number",
                    cellFilter: 'number: 5',
                    width: 90,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => { return this.SalesOrder ? this.SalesOrder.LockdownType < 1 : true },
                    cellClass: 'text-right',
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="orderedQty" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.00000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "ORDUOM",
                    displayName: "Order UoM",
                    field: "OrderUnitOfMeasureCode",
                    width: 90,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "PRCQTY",
                    displayName: "Price Qty",
                    cellFilter: 'number: 5',
                    field: "PriceQty",
                    type: "number",
                    width: 90,
                    cellClass: 'text-right',
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "PRCUOM",
                    displayName: "Price UoM",
                    field: "PricingUnitOfMeasureCode",
                    width: 90,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "BASEPRICE",
                    displayName: "* Base Cost Price",
                    field: "BaseCostPrice",
                    type: "number",
                    cellFilter: "number: 5",
                    cellClass: 'text-right',
                    width: 125,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => {
                        if (this.SalesOrder)
                        {
                            if (this.SalesOrder.LockdownType < 1 && this.SalesOrder.IsCostModelEnforced)
                            {
                                return true;
                            }
                        }
                        return false;
                    },
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="supplierPrice" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "SUPPRICE",
                    displayName: "* Selling Price",
                    field: "SellingPrice",
                    type: "number",
                    cellFilter: "number: 5",
                    cellClass: 'text-right',
                    width: 125,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => {
                        if (this.SalesOrder)
                        {
                            if (this.SalesOrder.LockdownType >= 1 || this.SalesOrder.IsCostModelEnforced)
                            {
                                return false;
                            }
                        }
                        return true;
                    },
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="supplierPrice" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "SUPVALUE",
                    displayName: "Selling Value",
                    field: "SellingValue",
                    type: "number",
                    cellFilter: "number: 2",
                    cellClass: 'text-right',
                    width: 125,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="sellingValue" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                    },
                    {
                        name: "VATVALUE",
                        displayName: "VAT Value",
                        field: "VATValue",
                        type: "number",
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        width: 125,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="VATValue" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    },
                    {
                    name: "CURRENCY",
                    displayName: "Currency",
                    field: "Currency",
                    width: 100,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }]
            };

            registerSkuGridApi(gridApi: uiGrid.IGridApi) {
                this.apiSOSkuList = gridApi;

                this.apiSOSkuList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiSOSkuList.selection.selectRow(gridSelection.row.entity);
                });
            }

            editSKULineClick(sosId: number) {
                if (!sosId)
                    sosId = 0;

                this.$state.go("auth.SalesOrders.Update.Sku", { soId: this.SalesOrder.Id, sosId: sosId });

              }



            addInlineSku() {
                var soSku = {
                    sosId: 0,
                    SalesOrderId: this.SalesOrder.Id,
                    CurrencyId: this.SalesOrder.Currency.Id,
                    Currency: this.SalesOrder.Currency.Code
                };

                (<Array<any>>(this.gvwSOSku.data)).splice(0, 0, soSku);
            }

            detailRemoveClick() {
                var selectedRows = <Array<interfaces.sales.ISalesOrderDetailSKUDisplay>>this.apiSOSkuList.selection.getSelectedRows();

                if (selectedRows && selectedRows.length <= 0) {
                    return;
                }

                if (!selectedRows[0].SalesOrderDetailId || selectedRows[0].SalesOrderDetailId === 0) {
                    this.apiSOSkuList.rowEdit.setRowsClean(selectedRows);
                    (<Array<any>>(this.gvwSOSku.data)).splice((<Array<any>>(this.gvwSOSku.data)).lastIndexOf(selectedRows[0]), 1);
                    return;
                }

                this.generalService.displayConfirmationBox("Confirm?", "Are you sure you want to remove the SKU Line?").then((result: boolean) => {
                    if (result) {
                        this.removeSKULine(selectedRows[0].SalesOrderDetailId);
                    }
                });
            }

            removeSKULine(salesOrderDetailId: number) {
                this.salesOrderSKUService.delete().delete({ salesOrderDetailId: salesOrderDetailId }, (result) => {
                    this.generalService.displayMessageHandler(result);

                    this.loadSO();

                }, (failureMessage) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                });
            }

            loadSKUs(searchText: string) {
                if (this.SalesOrder) {
                    return this.skuService.getDropdownList(this.SalesOrder.OwnerEntityId, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            skuChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.sales.ISalesOrderDetailSKUDisplay) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'so.update'
                    },
                        () => {
                            return this.skuService.getSKU().query({ skuId: model.Id }, (defaults: interfaces.master.ISKU) => {

                                entity.SalesOrderId = this.SalesOrder.Id;
                                entity.SKU = model;
                                entity.ProductId = defaults.ProductId;
                                entity.ProductCode = defaults.Product.Code;
                                entity.OrderUnitOfMeasureCode = defaults.Product.UnitOfMeasureCode;
                                entity.OrderUoMId = defaults.Product.OrderUnitOfMeasureId;
                                entity.PricingUnitOfMeasureCode = defaults.Product.UnitOfPricingCode;
                                entity.PricingUoMId = defaults.Product.PricingUnitOfMeasureId;
                                entity.OrderedQty = 0;
                                entity.PriceQty = 0;
                                entity.SellingPrice = 0;
                                entity.SellingValue = 0;

                                this.$timeout(() => {
                                    this.apiSOSkuList.rowEdit.setRowsDirty([entity]);
                                });
                            });
                        });
                }
            };

            generateFECNumber_Click()
            {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                }, () => {
                    return this.generateFECNumber().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.loadSO();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });

            };
        

            generateFECNumber() {
                return this.salesOrderService.generateFECNumber().save({
                    id: this.SalesOrder.Id
                }).$promise;
            };

            recalculateExchangeRate() {
                var defered = this.$q.defer();

                this.salesOrderService.recalculateExchangeRate().save(this.SalesOrder,
                    (result: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(result);
                        defered.resolve(result);

                    }, (failureMessage) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                        defered.reject(failureMessage);
                    });

                return defered.promise;
            }

            recalculateExchangeRate_Click() {
                this.generalService.displayConfirmationBox("Confirm", "Are you sure you want to recalculate the exchange rate?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'so.update'
                        },
                            () => {
                                return this.recalculateExchangeRate().then((data) => {
                                    this.$anchorScroll("topAnchor");
                                    this.loadSO();
                                }, (data) => {
                                    this.$anchorScroll("topAnchor");
                                });
                            })
                    }
                });
            }

            defaultComments_Click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                }, () => {
                    return this.defaultComments().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.loadSO();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });

            };

            defaultComments() {
                return this.salesOrderService.refreshComments().save({
                    id: this.SalesOrder.Id
                }).$promise;
            };

            generateSundryItems_Click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                }, () => {
                    return this.refreshSundryItems().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.loadSO();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });
            };

            refreshTariffCodeAndOriginClick() {
                this.generalService.displayConfirmationBox("Confirm?", "This will override any tarrifing for the country of export from the product master, are you sure you want to continue?").then((result: boolean) => {
                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'so.update'
                        }, () => {
                            return this.refreshTariffCodeAndOrigin().then((result) => {
                                this.generalService.displayMessageHandler(result);
                                this.loadSO();
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            });
                        });
                    }
                });
            };

            refreshTariffCodeAndOrigin(){
                return this.salesOrderService.refreshTariffCodeAndOrigin().save({
                    id: this.SalesOrder.Id
                }).$promise;
            }

            refreshSundryItems() {
                return this.salesOrderService.refreshSundryItems().save({
                    id: this.SalesOrder.Id
                }).$promise;
            };

            CostSalesOrder_Click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                }, () => {
                    return this.costSalesOrder().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.loadSO();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });
            };

            public costSalesOrder() {
                return this.salesOrderService.costSalesOrder().save({
                    id: this.SalesOrder.Id,
                    costModelId: this.SalesOrder.CostingModel.CostModel.Id
                }).$promise;
            }

            public loadCostingDetail() {
                return this.salesOrderService.getCostingLineListForMultipleCostings().query({ id: this.SalesOrder.Id }).$promise;
            }

            removeSundryLine_Click()
            {
                var selectedRows = <Array<interfaces.sales.ISalesOrderSundryItem>>this.apiSOSundryList.selection.getSelectedRows();

                if (selectedRows && selectedRows.length <= 0) {
                    return;
                }

                this.generalService.displayConfirmationBox("Confirm?", "Are you sure you want to remove the Sundry Line?").then((result: boolean) => {
                    if (result) {
                        this.removeSundryLine(selectedRows[0].Id);
                    }
                });
            }

            removeSundryLine(Id: number) {
                this.salesOrderSundryItemService.delete().delete({ sosId: Id }, (result) => {
                    this.generalService.displayMessageHandler(result);
                    this.loadSO();
                }, (failureMessage) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                });
            }

            printDocuments() {
                this.$state.go("auth.SalesOrders.Update.Documents", { soId: this.SalesOrder.Id });              
            }

            downloadPRToExcel() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                },
                    () => {
                        this.salesOrderService.GetSalesOrderPRListExcel(this.SalesOrder.Id, this.showOnlyProhibited).then(() => {
                        }, (data) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                        });
                    });
            }

            showOnlyPRProhibited() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                }, () => {
                        return this.salesOrderService.ViewDetailPR().query({
                            soId: this.SalesOrder.Id,
                            showOnlyProhibited: this.showOnlyProhibited,
                    }, (result: Array<interfaces.sales.ISalesOrderPRDisplay>) => {

                        this.SalesOrder.SalesOrderPRList = result;
                        this.gvwPRList.data = result;

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            }

            //////////////////////////////////////////////
            //P&R List.
            paginationPROptions = {
                pageNumber: 1,
                pageSize: 25
            };

            apiPRList: uiGrid.IGridApi;
            gvwPRList: uiGrid.IGridOptions | any = {
                data: [],
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: false,
                //Pagination
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                //Menu + Download
                enableGridMenu: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: false,
                exporterExcelFilename: "SalesOrderPRList.xlsx",
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['EDIT', 'Notes'],
                //Filters
                enableFiltering: true,
                useExternalFiltering: false,
                onRegisterApi: (gridApi) => { this.registerPRGridApi(gridApi) },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "",
                        width: 20,
                        enableFiltering: false,
                        enableSorting: false,
                        enableColumnMenu: false,
                    }, {
                        name: "ProductCode",
                        displayName: "Product Code",
                        field: "ProductCode",
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "ProductDescription",
                        displayName: "Description",
                        field: "ProductDescription",
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        width: 210,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "CountryCode",
                        displayName: "Country",
                        field: "CountryCode",
                        width: 73,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "Flow",
                        displayName: "Flow",
                        field: "Flow",
                        width: 60,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "Restriction",
                        displayName: "Restriction",
                        field: "Restriction",
                        width: 220,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}"><a href="/ApplicationCore/GTS/index.html#!/ProhibitedAndRestricted/RestrictionDetail/{{MODEL_COL_FIELD.Id}}" target="_blank">{{MODEL_COL_FIELD.Display}}</a></div>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return (searchTerm.length === 0 || cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                            }
                        },
                    }, {
                        name: "IsProhibited",
                        displayName: "Prohibited",
                        field: "IsProhibited",
                        width: 84,
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD">`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 200 }"/></div>
                            </div>`
                    }, {
                        name: "IsException",
                        displayName: "Is Exception",
                        field: "IsException",
                        width: 91,
                        cellTemplate: `<input type="checkbox" ng-disabled="false" ng-model="MODEL_COL_FIELD" ng-click="grid.appScope.salesOrderUpdateCtrl.permitExceptionChanged(row)">`,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        editableCellTemplate:
                            `   <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="checkbox" ng-disabled="false" ng-model="MODEL_COL_FIELD" ng-click="grid.appScope.salesOrderUpdateCtrl.permitExceptionChanged(row.entity)">
                            </p>
                        </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 200 }"/></div>
                            </div>`
                    }, {
                        name: "Permit",
                        displayName: "Permit",
                        field: "Permit",
                        width: 160,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                        <form name="inputForm">                                               
                            <p class="input-group-sm" ng-show="row.entity.RestrictionQuotaTypeId === 755">
                                <input type="text" name="PermitNumber-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD.Display" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor ng-change="grid.appScope.salesOrderUpdateCtrl.permitCodeChange(row)" />
                            </p>                           
                        </form>
                        <gts-dropdown ng-show="row.entity.RestrictionQuotaTypeId != 755" ui-select-wrap name="Permit-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" ng-change="grid.appScope.salesOrderUpdateCtrl.permitCodeDropdownChange(row)" load-data="grid.appScope.salesOrderUpdateCtrl.loadPermits(row, searchText)" pagination-search="true" refresh-delay="1000"></gts-dropdown>`,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.SalesOrder ? this.SalesOrder.LockdownType < 3 : true },
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                if (cellValue) {
                                    if (cellValue.Description) {
                                        return (searchTerm.length === 0 || cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                                    } else {
                                        return (searchTerm.length === 0 || cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                                    }
                                }
                            }
                        },
                    }, {
                        name: "PermitDisplay",
                        displayName: "",
                        field: "Permit",
                        width: 50,
                        enableFiltering: false,
                        enableSorting: false,
                        enableColumnMenu: false,
                        cellTemplate: `<div class="ui-grid-cell-contents"><a ng-show="{{MODEL_COL_FIELD.Id}}" href="/ApplicationCore/GTS/index.html#!/MasterData/Permit/UpdatePermit/{{MODEL_COL_FIELD.Id}}" target="_blank">View</a></div>`,
                    }, {
                        name: "IsQuota",
                        displayName: "Quota",
                        field: "IsQuota",
                        width: 58,
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD">`,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 200 }"/></div>
                        </div>`
                    }, {
                        name: "IsQuotaAllocated",
                        displayName: "Quota Allocated",
                        field: "IsQuotaAllocated",
                        width: 150,
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD">`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 200 }"/></div>
                            </div>`
                    }, {
                        name: "QuotaRequiredValue",
                        displayName: "Quota Value",
                        field: "QuotaRequiredValue",
                        width: 150,
                        cellFilter: "number: 2",
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "QuotaAllocatedValue",
                        displayName: "Allocated Value",
                        field: "QuotaAllocatedValue",
                        width: 150,
                        cellFilter: "number: 2",
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.SalesOrder ? this.SalesOrder.LockdownType < 3 : true },
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="number" ng-disabled="!(row.entity.RestrictionQuotaTypeId === 798 || row.entity.RestrictionQuotaTypeId === 740 || !row.entity.QuotaRequiredValue)" name="QuotaAllocatedValue-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" />
                                </p>
                            </form>`,
                    }, {
                        name: "QuotaCurrency",
                        displayName: "Currency",
                        field: "QuotaCurrency",
                        width: 75,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "QuotaRequiredAmount",
                        displayName: "Quota Amount",
                        field: "QuotaRequiredAmount",
                        width: 150,
                        cellFilter: "number: 2",
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    }, {
                        name: "QuotaAllocatedAmount",
                        displayName: "Allocated Amount",
                        field: "QuotaAllocatedAmount",
                        width: 150,
                        cellFilter: "number: 2",
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.SalesOrder ? this.SalesOrder.LockdownType < 3 : true },
                        filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                        editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="number" ng-disabled="!(row.entity.RestrictionQuotaTypeId === 798 || row.entity.RestrictionQuotaTypeId === 739 || !row.entity.QuotaRequiredAmount)" name="QuotaAllocatedAmount-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" />
                            </p>
                        </form>`,
                    }, {
                        name: "QuotaUnitOfMeasure",
                        displayName: "UoM",
                        field: "QuotaUnitOfMeasure",
                        width: 58,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    }, {
                        name: "TariffCode",
                        displayName: "Tariff Code",
                        field: "TariffCode",
                        width: 110,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "Comments",
                        displayName: "Comments",
                        field: "Comments",
                        width: 125,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }]
            };

            registerPRGridApi(gridApi: uiGrid.IGridApi) {
                this.apiPRList = gridApi;

                this.apiPRList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.PRListPageChange(newPage, pageSize) });

                this.apiPRList.cellNav.on.navigate(this.$scope, (newCol) => {
                    this.$timeout(() => {
                        this.apiPRList.selection.selectRow(newCol.row.entity);
                    });
                });
            }

            PRListPageChange(newPage, pageSize) {
                this.paginationPROptions.pageNumber = newPage;
                this.paginationPROptions.pageSize = pageSize;
            }

            loadPermits(row: uiGrid.IGridRow, searchText: string) {
                return this.salesOrderService.GetPermitsForDropdown().query({
                    salesOrderRestrictionId: row.entity.Id,
                    searchText: searchText
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            permitExceptionChanged(row: uiGrid.IGridRow) {
                this.apiPRList.rowEdit.setRowsDirty([row.entity]);
            }

            permitCodeChange(row: uiGrid.IGridRow) {
                this.apiPRList.rowEdit.setRowsDirty([row.entity]);
                row.entity.Permit.Id = null;
                row.entity.Permit.Description = row.entity.Permit.Display;
            }

            checkPR() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                }, () => {
                    return this.SaveSalesOrder().then(() => {
                        this.salesOrderService.CheckForPR(this.SalesOrder.Id).save((result) => {
                            this.generalService.displayMessageHandler(result);
                            this.$anchorScroll("topAnchor");

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'so.update'
                                    }, () => {
                                        return this.loadSO();
                                    });
                                });
                            }
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            this.$anchorScroll("topAnchor");
                            }).$promise;
                    }, () => {
                        return;
                    });
                });
            }

            addPermit() {
                if (this.apiPRList && this.apiPRList.selection.getSelectedRows().length > 0) {

                    var selected: interfaces.sales.ISalesOrderPRDisplay = this.apiPRList.selection.getSelectedRows()[0];

                    if (selected.Restriction === null) {
                        this.generalService.displayMessage("Only P&R Lines linked to restrictions can be split.", Enum.EnumMessageType.Warning);
                    } else {


                        var newPR = {
                            Id: selected.Id,
                            ProductCode: selected.ProductCode,
                            ProductDescription: selected.ProductDescription,
                            CountryCode: selected.CountryCode,
                            Flow: selected.Flow,
                            TariffCode: selected.TariffCode,
                            Restriction: selected.Restriction,
                            RestrictionQuotaTypeId: selected.RestrictionQuotaTypeId,
                            RestrictionQuotaTypeDesc: selected.RestrictionQuotaTypeDesc,
                            IsProhibited: true,
                            IsException: false,
                            IsQuotaAllocated: false,

                            QuotaRequiredValue: selected.QuotaRequiredValue,
                            QuotaCurrency: selected.QuotaCurrency,
                            QuotaRequiredAmount: selected.QuotaRequiredAmount,
                            QuotaUnitOfMeasure: selected.QuotaUnitOfMeasure,

                            Comments: selected.Comments,

                            RestrictionPermitId: 0,
                            Permit: null,
                            IsQuota: selected.IsQuota,
                            QuotaAllocatedValue: 0,
                            QuotaAllocatedAmount: 0,
                            RestrictionPermitActive: true
                        };

                        (<Array<any>>(this.gvwPRList.data)).splice(0, 0, newPR);
                    }

                } else {
                    this.generalService.displayMessage("Please select a P&R Line", Enum.EnumMessageType.Warning);
                }
            }

            removePermit() {
                if (this.apiPRList && this.apiPRList.selection.getSelectedRows().length > 0) {

                    var selected: interfaces.sales.ISalesOrderPRDisplay = this.apiPRList.selection.getSelectedRows()[0];
                    selected.RestrictionPermitActive = false;
                    this.apiPRList.rowEdit.setRowsDirty([selected]);

                    this.Save();

                } else {
                    this.generalService.displayMessage("Please select a P&R Line", Enum.EnumMessageType.Warning);
                }
            }

            runQuotaAllocation() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'so.update'
                }, () => {
                    return this.SaveSalesOrder().then(() => {
                        this.salesOrderService.RunQuotaAllocation(this.SalesOrder.Id).save({
                            soId: this.SalesOrder.Id,
                        }, (result) => {
                            this.generalService.displayMessageHandler(result);
                            this.$anchorScroll("topAnchor");

                            //if (!result.HasErrorMessage) { //this check has been removed as we always need to refresh the P&R grid after quota allocation
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'so.update'
                                    }, () => {
                                        return this.loadSO();
                                    });
                                });
                            //}
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            this.$anchorScroll("topAnchor");
                            });
                    }, () => {
                        return;
                    });
                });
            }

            registerConsignmentAttributeApi(gridApi: uiGrid.IGridApi) {
                this.apiCA = gridApi;
            }

            removeInlineCA() {
                var selectedRow = <interfaces.master.ICustomAttributeValue>this.apiCA.selection.getSelectedRows()[0];
                if (selectedRow && selectedRow.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update'
                    }, () => {
                        return this.customAttributeService.deleteCustomAttributeValue(selectedRow.Id).save({ Id: selectedRow.Id }, (result) => {
                            this.generalService.displayMessageHandler(result);
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'con.update'
                                }, () => {
                                        this.loadSO();
                                });
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                    });
                } else if (selectedRow) {
                    _.remove(this.gvwSalesOrderAttribute.data, (o) => {
                        return o === selectedRow;
                    });
                }
            }

            checkBoxAttributeChanged(entity) {
                if (this.apiCA) {
                    this.apiCA.rowEdit.setRowsDirty([entity]);
                }
            }

            attributeValueChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.master.ICustomAttributeValue) {
                if (model) {
                    entity.AttributeValue = model.Description;

                    if (this.apiCA) {
                        this.apiCA.rowEdit.setRowsDirty([entity]);
                    }
                }
            }

            attributeValueOtherChanged(entity: interfaces.master.ICustomAttributeValue) {
                if (entity) {
                    if (this.apiCA) {
                        this.apiCA.rowEdit.setRowsDirty([entity]);
                    }
                }
            }

            insertCA() {
                this.SalesOrder.SalesOrderAttributeList.push(<interfaces.master.ICustomAttributeValue>{
                    Id: 0,
                    Attribute: <interfaces.master.ICustomAttribute>{ Id: 0, AttributeType: 0, IsNotEditable: false },
                    CategoryType: 833,
                    CategoryKey: this.SalesOrder.Id,
                    IsActive: true
                });
            }

            addAllAttributes() {
                var typeId = 833

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update'
                }, () => {
                    return this.SaveSalesOrder().then(() => {
                        this.customAttributeService.generateMandatoryAttributes(this.SalesOrder.Id, typeId).save((result) => {
                            this.generalService.displayMessageHandler(result);

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'so.update'
                                    }, () => {
                                        return this.loadSO();
                                    });
                                });
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                    }, () => {
                        return;
                    });
                });
            }

            loadAttributeList() {
                var typeId = 833;
 

                return this.customAttributeService.GetCustomAttributeByCategory(typeId, this.SalesOrder.OwnerEntityId).query({
                }, (resultList) => {
                    this.attributeList = resultList;
                }).$promise;
            }

            loadAttributeAnswerOptionList(id) {
                return this.customAttributeAnswerOptionService.GetDropDownListByAttribute().query({
                    attributeId: id
                }, (resultList) => {
                    this.attributeAnswerOptionList = resultList;
                }).$promise;
            }

            gvwSalesOrderAttribute: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="col.colIndex()" class="ui-grid-cell" ng-disabled="row.entity.Attribute.IsNotEditable" ui-grid-cell></div>',
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => {
                    this.apiCA = gridApi;

                    this.apiCA.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiCA.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.salesOrderUpdateCtrl.removeInlineCA()" ng-disabled="row.entity.Attribute.IsNotEditable" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 80,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton">
                            <button type="button" class="btn btn-primary btn-sm" ng-click="grid.appScope.salesOrderUpdateCtrl.addAllAttributes()" >
                                <span title="Click to create mandatory list of attributes" class="fa fa-list"></span>
                            </button>
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.salesOrderUpdateCtrl.insertCA()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Attribute',
                        displayName: '* Attribute',
                        field: 'Attribute',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="row.entity.Attribute.IsNotEditable">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" ng-disabled="row.entity.Attribute.IsNotEditable" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.salesOrderUpdateCtrl.loadAttributeList()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 300
                    },
                    {
                        name: 'AttributeValue',
                        displayName: '* Attribute Value',
                        field: 'CustomAttributeAnswerOption',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeNumericValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeDateValue | momentDateFilter: 'YYYY/MM/DD  HH:mm'}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-dropdown ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable" ng-change="grid.appScope.salesOrderUpdateCtrl.attributeValueChanged(model, row.entity)"  ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}-AttributeValueDD" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.salesOrderUpdateCtrl.loadAttributeAnswerOptionList(row.entity.Attribute.Id)"></gts-dropdown>                                                                                                                                               
                                        
                                        <p class="input-group-sm" ng-if="row.entity.Attribute.AttributeType == 58">
                                        <input type="text" ng-change="grid.appScope.salesOrderUpdateCtrl.attributeValueOtherChanged( row.entity)" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValueText" ng-model="row.entity.AttributeValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>

                                        <p class="input-group-sm"  ng-if="row.entity.Attribute.AttributeType == 556">
                                        <input type="number" ng-change="grid.appScope.salesOrderUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValue" ng-model="row.entity.AttributeNumericValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" min="0" />
                                        </p>

                                        <gts-date ng-change="grid.appScope.salesOrderUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable"  format="yyyy/MM/dd HH:mm"  show-time="true"  ng-model="row.entity.AttributeDateValue" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                </form>
                               `,
                        enableSorting: false,
                        width: 300
                    }
                    , {
                        name: "IsActive",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive" ng-change="grid.appScope.salesOrderUpdateCtrl.checkBoxAttributeChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            };


 

            createNewVersion_Click() {
                this.generalService.displayConfirmationBox("Confirm?", "Create new version?").then((result: boolean) => {
                    if (result) {
                        this.createNewVersion(this.SalesOrder.Id,4,"Create New Version");
                    }
                });
            }

            createNewVersion(id: number, functionClassificationValueId: number, reason: string) {
                this.salesOrderService.CreateNewVersion().save(
                    { id, functionClassificationValueId, reason },
                    () => {
                        //Result
                        this.loadSO();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });               
            }

            incoTermChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.SalesOrder.Incoterm = model;
                this.SalesOrder.TermsOfDelivery = null;
                this.termsOfDeliveryList = [];
                if (model) {
                    this.loadTermsOfDeliveries();
                }
            }
        }

        angular.module("app").controller("salesOrderUpdateCtrl", controllers.sales.SalesOrderUpdateCtrl);
    }
}